<template>
  <h6 class="mb-0">
    <strong>{{ data.title }}</strong>
  </h6>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          title: 'Basic header',
        }
      },
    },
  },
}
</script>
