import App from './App.vue'
import store from './store'
import router from './router'
import { createApp } from 'vue'
import Antd from 'ant-design-vue'
import { i18n } from './localization'
import vueDebounce from 'vue-debounce'
import VMdEditor from '@kangc/v-md-editor'
import ImageFallback from 'vue-image-fallback'
import ImageResizer from '@/components/imageResizer'
import { SyncOutlined } from '@ant-design/icons-vue'
import PerfectScrollbar from 'vue3-perfect-scrollbar'
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js'
import VbHeadersCardHeader from '@/@vb/widgets/Headers/CardHeader'

import 'ant-design-vue/dist/antd.css'
import '@kangc/v-md-editor/lib/style/base-editor.css'
import '@kangc/v-md-editor/lib/theme/style/github.css'

// momentjs
import moment from 'moment'
import 'moment/locale/tr'
import trLocale from 'moment/locale/tr'

moment.locale('tr')
moment.updateLocale('tr', trLocale)

// highlightjs
import hljs from 'highlight.js'
import Maska from 'maska'
import enUS from '@kangc/v-md-editor/lib/lang/en-US';

VMdEditor.lang.use('en-US', enUS);
VMdEditor.use(githubTheme, {
  Hljs: hljs,
})

const app = createApp(App)
  .use(Maska)
  .use(store)
  .use(router)
  .use(i18n)
  .use(Antd)
  .use(PerfectScrollbar)
  .use(ImageFallback)
  .use(vueDebounce, {
    listenTo: ['input', 'keyup'],
    defaultTime: '700ms',
  })
  .use(VMdEditor)

app.component('ImageResizer', ImageResizer)
app.component('VbHeadersCardHeader', VbHeadersCardHeader)
app.component('SyncOutlined', SyncOutlined)
app.mount('#app')
