<template>
  <div :class="$style.footer">
    <div :class="$style.footerInner">
      <a
        href="https://onlinetraining.com.tr"
        target="_blank"
        rel="noopener noreferrer"
        :class="$style.logo"
      >
      </a>
      <br />
      <p class="mb-0">
        Copyright © {{ new Date().getFullYear() }}
        <a href="https://onlinetraining.com.tr" target="_blank" rel="noopener noreferrer">
          OnlineTraining
        </a>
        <!--
        <a href="https://sellpixels.com/privacy" target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>
        -->
      </p>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
export default {
  setup() {
    const store = useStore()
    const settings = computed(() => store.getters.settings)

    return {
      settings,
    }
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
