import localeAntd from 'ant-design-vue/lib/locale-provider/en_US'

const messages = {
  topBar: {
    issuesHistory: 'Hatalar',
    projectManagement: 'Proje',
    typeToSearch: 'Ara...',
    findPages: 'Sayfaları Ara...',
    actions: 'İşlemler',
    status: 'Durum',
    profileMenu: {
      hello: 'Merhaba',
      billingPlan: 'Billing Plan',
      role: 'Role',
      email: 'Email',
      phone: 'Telefon',
      editProfile: 'Profili Düzenle',
      logout: 'Çıkış Yap',
    },
  },
}

export default {
  locale: 'en-US',
  localeAntd,
  messages,
}
