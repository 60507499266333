export function getRandomInt(min, max) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min) + min)
}

export const roundHalf = num => {
  if (!num) return 0
  return Math.round(num * 2) / 2
}
