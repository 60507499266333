// import apiClient from '@/services/axios'
import store from 'store'
// import { Account } from '../api-service'

export async function login(email, password) {
  // const response = await Account.login({ email: email, password: password })
  //
  // if (response) {
  //   const { jwt } = response.data
  //   if (jwt) {
  //     store.set('accessToken', jwt)
  //   }
  //   return response.data
  // }
  // return false
}

export async function register(email, password, name) {
  // return apiClient
  //   .post('/auth/register', {
  //     email,
  //     password,
  //     name,
  //   })
  //   .then(response => {
  //     if (response) {
  //       const { accessToken } = response.data
  //       if (accessToken) {
  //         store.set('accessToken', accessToken)
  //       }
  //       return response.data
  //     }
  //     return false
  //   })
  //   .catch(err => console.log(err))
}

export async function currentAccount() {
  // return apiClient
  //   .get('/auth/account')
  //   .then(response => {
  //     if (response) {
  //       const { accessToken } = response.data
  //       if (accessToken) {
  //         store.set('accessToken', accessToken)
  //       }
  //       return response.data
  //     }
  //     return false
  //   })
  //   .catch(err => console.log(err))
  return false
}

export async function logout() {
  store.remove('accessToken')
  return true
}
