<template>
  <div class='container-fluid'>
    <div class='row'>
      <label style='color:#595c97'>Fotoğraf {{required ? "(*)" : ""}}</label>
    </div>
    <div class='row'>
      <div class='col'>
        <div class='d-flex justify-content-end'>
          <div class='d-flex justify-content-center align-items-center border rounded-lg bg-light preview-image'>
            <img v-if='!changed' :src='initialImage' alt='initialImage' @error='fallback' />
            <canvas class='canvas' :id='`canvas${randKey}`' />
          </div>
        </div>
      </div>
      <div class='col'>
        <a-upload
          name='avatar'
          list-type='picture-card'
          class='avatar-uploader d-flex justify-content-start'
          :show-upload-list='false'
          :multiple='false'
          :before-upload='beforeUpload'
        >
          <div>
            <loading-outlined v-if='loading'></loading-outlined>
            <plus-outlined v-else></plus-outlined>
            <div class='ant-upload-text'>Fotoğraf Yükle</div>
          </div>
        </a-upload>
      </div>
    </div>
    <div class='row' v-if='errorText !== ""'>
      <div class='col d-flex justify-content-center'>
        <a-tag color='warning' class='mt-1'>
          <template #icon>
            <i class='fe fe-alert-circle' />
          </template>
          {{ errorText }}
        </a-tag>
      </div>
    </div>

    <div class='row' v-if='maxSize !== 0 && errorText === ""'>
      <div class='col d-flex justify-content-center'>
        <a-tag color='warning' class='mt-1'>
          <template #icon>
            <i class='fe fe-alert-circle' />
          </template>
          Minimum Fotoğraf Boyutu {{ maxSize }} Piksel Olmalıdır!
        </a-tag>
      </div>
    </div>
  </div>

</template>

<script>
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { watch } from 'vue'
import { getRandomInt } from '@/utils/number'

export default {
  name: 'ImageResizer',
  components: {
    PlusOutlined,
    LoadingOutlined,
  },
  props: {
    maxSize: {
      type: Number,
      default: 0,
      required: false,
    },
    onComplete: {
      type: Function,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
      required: true,
    },
    initialImage: {
      type: String,
      required: false,
      default: '',
    },
    makeSquare: {
      type: Boolean,
      required: false,
      default: false,
    },
    errorText: {
      type: String,
      required: false,
      default: '',
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    const getTime = getRandomInt(100000, 999999)

    watch(() => this.initialImage, () => {
      const canvas = document.getElementById(`canvas${this.randKey}`)
      const ctx = canvas.getContext('2d')
      ctx.clearRect(0, 0, canvas.width, canvas.height)

      this.changed = false
      this.randKey = getRandomInt(100000, 999999)
    })

    return {
      changed: false,
      randKey: getTime,
    }
  },
  methods: {
    fallback(event) {
      event.target.src = '/resources/images/no-image.png'
    },
    beforeUpload(file) {
      const canvas = document.getElementById(`canvas${this.randKey}`)
      const ctx = canvas.getContext('2d')

      const maxW = this.maxSize
      const maxH = this.maxSize

      const img = new Image

      const onLoad = () => {
        const iw = img.width
        const ih = img.height
        const scale = maxW / iw

        const iwScaled = Math.round(iw * scale)
        const ihScaled = Math.round(ih * scale)

        if (this.makeSquare) {
          const minCorner = Math.min((iw), (ih))
          const maxCorner = Math.max((iw), (ih))

          const calculateMargin = (maxCorner - minCorner) / 2

          canvas.width = Math.max((maxW), (maxH))
          canvas.height = Math.max((maxW), (maxH))

          ctx.drawImage(img, iw === maxCorner ? calculateMargin : 0, ih === maxCorner ? calculateMargin : 0, minCorner, minCorner, 0, 0, canvas.width, canvas.height)

        } else {
          canvas.width = iwScaled
          canvas.height = ihScaled

          ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
        }

        if (typeof this.onComplete === 'function') {
          this.onComplete(canvas.toDataURL('image/jpeg', 1))
          this.changed = true
        }
      }

      img.onload = onLoad.bind(this)
      img.src = URL.createObjectURL(file)
      return false
    },
  },
}
</script>

<style scoped>
.canvas {
  max-width: 100%;
  max-height: 100%;
}

.preview-image {
  width: 104px;
  height: 104px;
  padding: 8px;
  position: relative;
}

.preview-image > img {
  padding: 8px;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto
}
</style>
