export const getMenuData = [
  // VB:REPLACE-START:MENU-CONFIG
  {
    category: true,
    title: 'ANA MENÜ',
    key: '1se5ht',
  },
  {
    title: 'Anasayfa',
    key: '__dashboard',
    url: '/',
    icon: 'fe fe-activity',
  },
  {
    category: true,
    title: 'KULLANICI YÖNETİMİ',
    key: '1se5ht',
  },
  {
    title: 'Antrenörler',
    key: 'xeqrd5',
    url: '/antrenorler',
    icon: 'fe fe-star',
  },
  {
    title: 'Kullanıcılar',
    key: 'tz32gb5',
    url: '/kullanicilar',
    icon: 'fe fe-user',
  },
  {
    title: 'Sistem Yöneticileri',
    key: 'gf74hz',
    url: '/sistem-yoneticileri',
    icon: 'fe fe-user-plus',
  },
  {
    category: true,
    title: 'UYGULAMA YÖNETİMİ',
    key: 'rgwphj',
  },
  {
    title: 'Ders Paketleri',
    key: 'k4fful',
    url: '/ders-paketleri',
    icon: 'fe fe-layers',
  },
  {
    title: 'Yayın Takvimi',
    key: 'ehvvn',
    url: '/yayin-takvimi',
    icon: 'fe fe-cast',
  },
  {
    title: 'Reklam Yönetimi',
    key: 'heima',
    url: '/reklam-yonetimi',
    icon: 'fe fe-activity',
  },
  {
    title: 'Üyelik Paketleri',
    key: 'j9yv75',
    url: '/uyelik-paketleri',
    icon: 'fe fe-archive',
  },
  {
    title: 'Legal Dökümanlar',
    key: 'ei6da',
    url: '/legal-dokumanlar',
    icon: 'fe fe-file',
  },
  {
    title: 'Şikayetler',
    key: '4an6j',
    url: '/sikayetler',
    icon: 'fe fe-info',
  },
  // {
  //   title: 'Ödemeler',
  //   key: 'nl790f',
  //   url: '/odemeler',
  //   icon: 'fe fe-dollar-sign',
  // },
  {
    title: 'Bildirimler',
    key: 'c2d31sz',
    url: '/bildirimler',
    icon: 'fe fe-bell',
  },
  {
    title: 'Slider Yönetimi',
    key: 'c2d31sd',
    url: '/slider-yonetimi',
    icon: 'fa fa-image',
  },
  {
    title: 'S.S.S. Yönetimi',
    key: 'cx1d1w3',
    url: '/s-s-s-yonetimi',
    icon: 'fa fa-question-circle-o',
  },
  {
    category: true,
    title: 'BAŞARIMLAR',
    key: 'rgwpxx',
  },
  {
    title: 'Rozetler',
    key: 'gg4g9g',
    url: '/rozetler',
    icon: 'fe fe-award',
  },
  {
    category: true,
    title: 'SİSTEM YÖNETİMİ',
    key: 'rgwpxj',
  },
  {
    title: 'Ayarlar',
    key: 'dxopei',
    url: '/ayarlar',
    icon: 'fe fe-settings',
  },

  // VB:REPLACE-END:MENU-CONFIG
]

export const getMenuDataWithSubCategory = [
  // VB:REPLACE-START:MENU-CONFIG
  ...getMenuData,
  {
    title: 'Ders Paketi Ekle',
    url: '/paket-dersi-ekle',
  },
  {
    title: 'Yeni Antrenör Ekle',
    url: '/yeni-antrenor-ekle',
  },
  {
    title: 'Yeni Yayın Ekle',
    url: '/yeni-yayin-ekle',
  },
  {
    title: 'Ders Paket Detayları',
    url: '/ders-paket-detaylari',
  },
  {
    title: 'Yeni Slider Ekle',
    url: '/yeni-slider-ekle',
  },
  {
    title: 'Legal Döküman Düzenle',
    url: '/legal-dokuman-duzenleme',
  },
  {
    title: 'Şikayet Detayları',
    url: '/sikayet-detaylari',
  },
  // VB:REPLACE-END:MENU-CONFIG
]
