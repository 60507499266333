import config from '../config'
import apiClient from './axios'
import * as Services from '@tetahq/bironbir_api_client'

export const appInfo = Services.AppInfoApiFactory(
  undefined,
  config.api.baseUrl,
  apiClient,
)
export const LegalDocument = Services.LegalDocumentApiFactory(
  undefined,
  config.api.baseUrl,
  apiClient,
)
export const Advertise = Services.AdvertiseApiFactory(
  undefined,
  config.api.baseUrl,
  apiClient,
)
export const BroadcastProgram = Services.BroadcastProgramApiFactory(
  undefined,
  config.api.baseUrl,
  apiClient,
)
export const Carousel = Services.CarouselApiFactory(
  undefined,
  config.api.baseUrl,
  apiClient,
)
export const CustomerService = Services.CustomerServiceApiFactory(
  undefined,
  config.api.baseUrl,
  apiClient,
)
export const PackageCourse = Services.PackageCourseApiFactory(
  undefined,
  config.api.baseUrl,
  apiClient,
)
export const PersonalTrainer = Services.PersonalTrainerApiFactory(
  undefined,
  config.api.baseUrl,
  apiClient,
)
export const SystemController = Services.SystemApiFactory(
  undefined,
  config.api.baseUrl,
  apiClient,
)
export const Account = Services.AccountApiFactory(
  undefined,
  config.api.baseUrl,
  apiClient,
)
export const UserJwt = Services.UserJwtControllerApiFactory(
  undefined,
  config.api.baseUrl,
  apiClient,
)
export const Live = Services.LiveApiFactory(
  undefined,
  config.api.baseUrl,
  apiClient,
)
export const AdministratorAdministration = Services.AdministratorAdministrationApiFactory(
  undefined,
  config.api.baseUrl,
  apiClient,
)
export const appInfoAdministration = Services.AppInfoAdministrationApiFactory(
  undefined,
  config.api.baseUrl,
  apiClient,
)
export const legalDocumentAdministration = Services.LegalDocumentAdministrationApiFactory(
  undefined,
  config.api.baseUrl,
  apiClient,
)
export const AdvertiseAdministration = Services.AdvertiseAdministrationApiFactory(
  undefined,
  config.api.baseUrl,
  apiClient,
)
export const BroadcastProgramAdministration = Services.BroadcastProgramAdministrationApiFactory(
  undefined,
  config.api.baseUrl,
  apiClient,
)
export const CarouselAdministration = Services.CarouselAdministrationApiFactory(
  undefined,
  config.api.baseUrl,
  apiClient,
)
export const CustomerServiceAdministration = Services.CustomerServiceAdministrationApiFactory(
  undefined,
  config.api.baseUrl,
  apiClient,
)
export const PackageCourseAdministration = Services.PackageCourseAdministrationApiFactory(
  undefined,
  config.api.baseUrl,
  apiClient,
)
export const PersonalTrainerAdministration = Services.PersonalTrainerAdministrationApiFactory(
  undefined,
  config.api.baseUrl,
  apiClient,
)
export const CustomerAdministration = Services.CustomerAdministrationApiFactory(
  undefined,
  config.api.baseUrl,
  apiClient,
)
export const SubscriptionPlanAdministration = Services.SubscriptionPlanAdministrationApiFactory(
  undefined,
  config.api.baseUrl,
  apiClient,
)
export const NotificationAdministration = Services.NotificationAdministrationApiFactory(
  undefined,
  config.api.baseUrl,
  apiClient,
)
export const OneSignalAdministration = Services.OnesignalApiFactory(
  undefined,
  config.api.baseUrl,
  apiClient,
)
