import { createRouter, createWebHistory } from 'vue-router'
import NProgress from 'nprogress'
import AuthLayout from '@/layouts/Auth'
import MainLayout from '@/layouts/Main'
import store from '@/store'

const router = createRouter({
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      redirect: '/',
      component: MainLayout,
      meta: {
        authRequired: true,
        hidden: true,
      },
      children: [
        // VB:REPLACE-START:ROUTER-CONFIG
        {
          path: '/',
          meta: { title: 'Anasayfa' },
          component: () => import('./views/anasayfa'),
        },
        {
          path: '/antrenorler',
          meta: { title: 'Antrenörler' },
          component: () => import('./views/antrenorler'),
        },
        {
          path: '/yeni-antrenor-ekle',
          meta: { title: 'Yeni Antrenör Ekle' },
          component: () => import('./views/antrenorler/newPT'),
        },
        {
          path: '/kullanicilar',
          meta: { title: 'Kullanıcılar' },
          component: () => import('./views/kullanicilar'),
        },
        {
          path: '/sistem-yoneticileri',
          meta: { title: 'Yeni Sistem Yöneticisi Ekle' },
          component: () => import('./views/sistem-yoneticileri'),
        },
        {
          path: '/ders-paketleri',
          meta: { title: 'Ders Paketleri' },
          component: () => import('./views/ders-paketleri'),
        },
        {
          path: '/paket-dersi-ekle',
          meta: { title: 'Ders Paketi Ekleme' },
          component: () => import('./views/paket-dersi-ekle'),
        },
        {
          path: '/ders-paket-detaylari/:id',
          meta: { title: 'Ders Paket Detayları' },
          component: () => import('./views/ders-paket-detaylari'),
        },
        {
          path: '/yayin-takvimi',
          meta: { title: 'Yayın Takvimi' },
          component: () => import('./views/yayin-takvimi'),
        },
        {
          path: '/yeni-yayin-ekle',
          meta: { title: 'Yeni Yayın Ekle' },
          component: () => import('./views/yeni-yayin-ekle'),
        },
        {
          path: '/reklam-yonetimi',
          meta: { title: 'Reklam Yönetimi' },
          component: () => import('./views/reklam-yonetimi'),
        },
        {
          path: '/uyelik-paketleri',
          meta: { title: 'Üyelik Paketleri' },
          component: () => import('./views/uyelik-paketleri'),
        },
        {
          path: '/rozetler',
          meta: { title: 'Rozetler' },
          component: () => import('./views/rozetler'),
        },
        {
          path: '/legal-dokumanlar',
          meta: { title: 'Legal Dökümanlar' },
          component: () => import('./views/legal-dokumanlar'),
        },
        {
          path: '/legal-dokuman-duzenleme/:id',
          meta: { title: 'Legal Döküman Düzenleme' },
          component: () => import('./views/legal-dokuman-duzenleme'),
        },
        {
          path: '/sikayetler',
          meta: { title: 'Şikayetler' },
          component: () => import('./views/sikayetler'),
        },
        {
          path: '/sikayet-detaylari/:id',
          meta: { title: 'Şikayet Detayları' },
          component: () => import('./views/sikayet-detaylari'),
        },
        // {
        //   path: '/odemeler',
        //   meta: { title: 'Ödemeler' },
        //   component: () => import('./views/odemeler'),
        // },
        {
          path: '/slider-yonetimi',
          meta: { title: 'Slider Yönetimi' },
          component: () => import('./views/slider-yonetimi'),
        },
        {
          path: '/yeni-slider-ekle',
          meta: { title: 'Yeni Slider Ekle' },
          component: () => import('./views/slider-yonetimi/yeni-slider-ekle'),
        },
        {
          path: '/ayarlar',
          meta: { title: 'Ayarlar' },
          component: () => import('./views/ayarlar'),
        },
        {
          path: '/s-s-s-yonetimi',
          meta: { title: 'S.S.S Yönetimi' },
          component: () => import('./views/s-s-s-yonetimi'),
        },
        {
          path: '/bildirimler',
          meta: { title: 'Bildirimler' },
          component: () => import('./views/bildirimler'),
        },

        // VB:REPLACE-END:ROUTER-CONFIG
      ],
    },

    // System Pages
    {
      path: '/auth',
      component: AuthLayout,
      redirect: 'auth/login',
      children: [
        {
          path: '/auth/404',
          name: 'route404',
          meta: {
            title: 'Error 404',
          },
          component: () => import('./views/auth/404'),
        },
        {
          path: '/auth/500',
          meta: {
            title: 'Error 500',
          },
          component: () => import('./views/auth/500'),
        },
        {
          path: '/auth/login',
          name: 'login',
          meta: {
            title: 'Giriş Yap',
          },
          component: () => import('./views/auth/login'),
        },
      ],
    },

    // Redirect to 404
    {
      path: '/:pathMatch(.*)*',
      redirect: { name: 'route404' },
    },
  ],
})

router.beforeEach((to, from, next) => {
  NProgress.start()
  setTimeout(() => {
    NProgress.done()
  }, 300)

  if (to.matched.some(record => record.meta.authRequired)) {
    if (!store.state.user.authorized) {
      next({
        path: '/auth/login',
        query: { redirect: to.fullPath },
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
