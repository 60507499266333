import axios from 'axios'
import store from 'store'
import config from '../../config'
import { notification } from 'ant-design-vue'

const apiClient = axios.create({
  baseURL: config.api.baseURL,
  withCredentials: true,
})

apiClient.interceptors.request.use(request => {
  const accessToken = store.get('accessToken')
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
    request.headers.AccessToken = accessToken
  }
  return request
})

apiClient.interceptors.response.use(
  value => {
    if (value.data.jwt) {
      // store.set('accessToken', value.data.jwt)
    }
    return value
  },
  error => {
    // Errors handling

    switch (error.response?.data.title) {
      case 'USER_ACCOUNT_NOT_FOUND':
        notification.error({ message: 'Kullanıcı Hesabı Bulunamadı!' })
        break
      case 'BROADCAST_PROGRAM_NOT_FOUND':
        notification.error({ message: 'Canlı Yayın Bulunamadı!' })
        break
      case 'BROADCAST_PROGRAM_START_DATE_GREATER_THAN_END_DATE':
        notification.error({
          message: 'Canlı yayının başlangıç tarihi, bitiş tarihinden önce olmalıdır!',
        })
        break
      case 'BROADCAST_PROGRAM_START_DATE_EQUAL_END_DATE':
        notification.error({ message: 'Canlı yayının başlangıç ve bitiş tarihi aynı olamaz!' })
        break
      case 'PERSONAL_TRAINER_NOT_FOUND':
        notification.error({ message: 'Antrenör Bulunamadı!' })
        break
      case 'PERSONAL_TRAINER_ACCOUNT_NOT_FOUND':
        notification.error({ message: 'Antrenöre ait hesap bulunamadı!' })
        break
      case 'IMAGE_COULD_NOT_BE_SAVED':
        notification.error({ message: 'Resim Kaydedilemedi!' })
        break
      case 'PLEASE_ENTER_THE_PARAMETERS_YOU_WANT_TO_UPDATE':
        notification.error({ message: 'Güncellemek için değer giriniz!' })
        break
      case 'USER_NOT_FOUND':
        notification.error({ message: 'Kullanıcı Bulunamadı!' })
        break
      case 'LEGAL_DOCUMENT_NOT_FOUND':
        notification.error({ message: 'Yasal Döküman Bulunamadı!' })
        break
      case 'PERSONAL_TRAINER_METADATA_NOT_FOUND':
        notification.error({ message: 'Antrenöre ait bilgiler bulunamadı!' })
        break
      case 'USER_NOT_FOUND_WITH_RECOVER_CODE':
        notification.error({ message: 'Kurtarılacak kullanıcı bulunamadı!' })
        break
      case 'USER_HAS_ALREADY_ACTIVATED':
        notification.error({ message: 'Kullanıcı hesabı zaten onaylanmış!' })
        break
      case 'COMPLAINT_NOT_FOUND':
        notification.error({ message: 'Şikayet Bulunamadı!' })
        break
      case 'USER_EMAIL_HAS_ALREADY_USED':
        notification.error({ message: 'Bu Email Zaten Kullanılıyor.' })
        break
      case 'INVALID_ACTIVATION_CODE_EXCEPTION':
        notification.error({ message: 'Aktivasyon Kodu Hatası!' })
        break
      case 'CAN_NOT_PROCESS_THE_FILE':
        notification.error({ message: 'Dosya Yüklenirken Hata Oluştu!' })
        break
      case 'INVALID_PASSWORD':
        notification.error({ message: 'Geçersiz Şifre' })
        break
      case 'EMAIL_ALREADY_IN_USE':
        notification.error({ message: 'Bu Email Zaten Kullanılıyor' })
        break
      case 'PACKAGE_COURSE_NOT_FOUND':
        notification.error({ message: 'Antreman Paketi Bulunamadı' })
        break
      case 'PACKAGE_COURSE_TRAINING_NOT_FOUND':
        notification.error({ message: 'Antreman Paketi İçeriği Bulunamadı' })
        break
      case 'PACKAGE_COURSE_TRAINING_NOT_FOUND_LINKED_TO_PACKAGE_COURSE':
        notification.error({ message: 'Antreman Paketi İçeriği Bulunamadı' })
        break
      case 'PACKAGE_COURSE_TRAINING_SET_NOT_FOUND':
        notification.error({ message: 'Antreman Paketinin Setleri Bulunamadı' })
        break
      case 'AGORA_ROOM_INFO_NOT_FOUND':
        notification.error({ message: 'Yayın Bulunamadı!' })
        break
      case 'AGORA_ROOM_INFO_IS_NOT_LIVE':
        notification.error({ message: 'Yayın Canlı Değildir.' })
        break
      case 'NON_PREMIUM_ACCOUNTS_JOIN_LIVE_ROOM_ONLY_ONCE_A_DAY':
        notification.error({ message: 'Daha Fazla Günlük Canlı Yayın Hakkınız İçin Premium Olun' })
        break
      case 'INVALID_LOGIN':
        notification.error({ message: 'Email veya Şifreniz Hatalı' })
        break
      case 'VIMEO_URL_IS_NOT_VALID':
        notification.error({ message: 'Video URLsi Geçersiz' })
        break
      case 'VIMEO_VIDEO_NOT_FOUND':
        notification.error({ message: 'Video Bulunamadı' })
        break
      case 'SUBSCRIPTION_PLAN_NOT_FOUND':
        notification.error({ message: 'Abonelik Paketi Bulunamadı' })
        break
      case 'CUSTOMER_METADATA_NOT_FOUND':
        notification.error({ message: 'Kullanıcı Bilgileri Bulunamadı' })
        break
      case 'OPERATION_NOT_ALLOWED':
        notification.error({ message: 'Yetkisiz İşlem' })
        break
      case 'BROADCAST_IS_NOT_ENDED_YET':
        notification.error({ message: 'Canlı Yayın Henüz Sonlanmadı!' })
        break
      case 'USER_NOT_JOINED_YET':
        notification.error({ message: 'Kullanıcı Henüz Katılmadı!' })
        break
      case 'USER_IS_NOT_BROADCASTER':
        notification.error({ message: 'Odanın Sahibi Değilsiniz' })
        break
      case 'BROADCAST_IS_ENDED':
        notification.error({ message: 'Yayın Tamamlanmış' })
        break
      case 'PAYMENT_FAILED':
        notification.error({ message: 'Ödeme Yaparken Hata Oluştu' })
        break
      case 'YOU_ARE_NOT_IN_ROOM':
        notification.error({ message: 'Yayına Katılamadınız!' })
        break
      case 'IYZICO_SIGNATURE_NOT_VALID':
        notification.error({ message: 'İyzico İmza Hatası!' })
        break
      case 'PAYMENT_NOT_FOUND':
        notification.error({ message: 'Ödeme Bulunamadı!' })
        break
      case 'USER_ALREADY_HAS_SUBSCRIPTION':
        notification.error({ message: 'Zaten Abonesiniz!' })
        break
      case 'SUBSCRIPTION_DOWNGRADE_NOT_ALLOWED':
        notification.error({ message: 'Abonelik Paketinizi Düşüremezsiniz!' })
        break
      case 'MINIMUM_PHOTO_SIZE':
        notification.error({
          message: `Fotoğraf Boyutu Minimum "${error.response?.data.minimumSize}" Piksel olmalıdır.`,
          description: `Mevcut Fotoğraf "${error.response?.data.currentSize}" Pikseldir.`,
        })
        break
      case 'PHOTO_MUST_BE_SQUARE':
        notification.error({ message: 'Fotoğraf Kare Olmalıdır!' })
        break
      case 'CAROUSEL_IMAGE_NOT_FOUND':
        notification.error({ message: 'Slider Bulunamadı!' })
        break
      case 'NO_USERS_FOUND':
        notification.error({ message: 'Kullanıcılar Bulunamadı!' })
        break
    }

    switch (error.response?.data.errorKey) {
      case 'emailexists':
        notification.error({ message: 'Girdiğiniz Email Zaten Kayıtlı' })
        break
    }

    switch (error.response?.data.detail) {
      case 'Full authentication is required to access this resource':
        notification.error({ message: 'Oturum süreniz sona erdi, Tekrar Giriş Yapmalısınız!' })
        localStorage.clear()
        break
    }

    if (error.response.status === 500) {
      notification.error({ message: 'Beklenmeyen Sunucu Hatası!' })
    } else if (error.response.status === 404) {
      notification.error({ message: 'Beklenmeyen Sunucu Hatası!' })
    } else if (error.response.status === 400) {
      notification.error({ message: 'Method kullanımı hatası, lütfen yazılım ekibine bildirin.' })
    }

    throw new Error(error)
  },
)

export default apiClient
