<template>
  <styleLoader />
  <localization />
</template>

<script>
import { computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import qs from 'qs'
import Localization from '@/localization'
import StyleLoader from '@/styleLoader'
import { CustomerServiceAdministration } from './services/api-service'

export default {
  name: 'App',
  components: { Localization, StyleLoader },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const logo = computed(() => store.getters.settings.logo)
    const routeTitle = computed(() => route.meta.title)
    const currentRoute = computed(() => route)
    const authorized = computed(() => store.getters['user/user'].authorized)

    // watch page title change
    watch(
      [logo, routeTitle],
      ([logo, routeTitle]) => (document.title = `${logo} | ${routeTitle}` || `${logo}`),
    )

    // redirect if authorized and current page is login
    watch(authorized, authorized => {
      if (authorized) {

        (async () => {
          try {
            const { data } = await CustomerServiceAdministration.listComplaints(1)
            const find = data.complaintList.find(x => x.complaintState === 'NOT_SEEN_YET')
            if(find){
              store.commit('CHANGE_SETTING', {
                setting: 'hasNewNotSeenComplaint',
                value: true,
              })
            }else{
              store.commit('CHANGE_SETTING', {
                setting: 'hasNewNotSeenComplaint',
                value: false,
              })
            }
          } catch {
          }
        })()

        const query = qs.parse(currentRoute.value.fullPath.split('?')[1], {
          ignoreQueryPrefix: true,
        })
        router.push(query.redirect || '/')
      }
    })
  },
}
</script>
