<template>
  <a-menu-item :key='menuInfo.key' v-bind='$props' :disabled='menuInfo.disabled'>
    <router-link v-if='menuInfo.url' :to='menuInfo.url'>
      <span v-if='!settings.isMenuCollapsed || settings.isMobileView' :class='styles.title'>{{ menuInfo.title }}</span>
      <span v-if='menuInfo.count' class='badge badge-success ml-2'>{{ menuInfo.count }}</span>
      <i v-if='menuInfo.icon' :class='[styles.icon, menuInfo.icon]' />
    </router-link>
    <span v-if='!menuInfo.url' :to='menuInfo.url'>
      <span :class='styles.title'>{{ menuInfo.title }}</span>
      <span v-if='menuInfo.count' class='badge badge-success ml-2'>{{ menuInfo.count }}</span>
      <i v-if='menuInfo.icon' :class='[styles.icon, menuInfo.icon]' />
    </span>
  </a-menu-item>
</template>

<script>
import { Menu } from 'ant-design-vue'
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'SubMenu',
  props: {
    ...Menu.Item.props,
    menuInfo: {
      type: Object,
      default: () => {
      },
    },
    styles: {
      type: Object,
      default: () => {
      },
    },
  },
  setup() {
    const store = useStore()
    const settings = computed(() => store.getters.settings)
    return {
      settings,
    }
  },
}
</script>
